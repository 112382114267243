/* removed tailwind because it isn't working */

:root {
  --mainColor: #602b16
}

html {
  height: 100%;
  width: 100%;
  background-color: #041e1d;
}

body {
  height: 100%;
  width: 100%;
  /* background-color: #0b0b0b; */
  position: relative;
  margin: 0;
  padding: 0;
}

.mmo-logo {
  position: absolute;
  top: 24px;
  left: 50%;
  width: 120px;
  transform: translateX(-50%);
  height: auto;
  filter: drop-shadow(3px 3px 20px rgb(12 14 16 / 1));
}
.mmo-logo path {
  fill: white;
}

.vidbg-container {
  position: relative;
  height: 100%;
  width: 100%;
  z-index: -1;
  overflow-x: hidden;
}

.vidbg-container video {
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
}

.current-news {
  position: absolute;
  text-align: center;
  bottom: 0;
  font-family: sans-serif;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: -0.01em;
  color: #ffffff;
  width: 100%;
  margin-top: 16px;
  font-family: 'Padauk', sans-serif;
}

.current-news p {
  margin-bottom: 0;
  margin-top: 0;
  /* text-shadow: 0 0 15px rgba(4,30,29,0.6); */
  background-color: rgba(13, 14,16,0.75);
  padding: 12px 0 0 0;
}
.shift-left {
  margin-left: -6px;
}

.song-image img {
  width: 300px;
  height: auto;
  margin: auto;
  margin-top: 24px;
  margin-bottom:8px;
}

.social-list {
  list-style-type: none;
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
  background-color: rgba(13, 14,16,0.75);
}

.social-icon {
  padding: 10px 16px 16px 16px;
}
.social-icon a i {
  color: #ffffff;
  font-size: 32px;
  width: 32px;
  transition: color 0.25s ease;
  /* filter: drop-shadow(3px 3px 20px rgb(4 30 29 / 1)); */
}
.social-icon a:hover i, .social-icon a:focus i {
  color: #6bede7;
}

@media (min-width: 768px) {
  .current-news {
    bottom: 32px;
    right: 32px;
    width: auto;
  }
  .current-news p {
    width: 78%;
    margin-left: auto;
    margin-right: auto;
  }
  .song-image img {
    width: 480px;
  }
  .social-list {
    width: 78%;
    margin-left: auto;
    margin-right: auto;
  }
}